import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image = require("../../../assets/img/blogs/gahs_blog_header.png");
const section_1 = require("../../../assets/img/blogs/gahs_blog_img_1.png");
const section_2_1 = require("../../../assets/img/blogs/gahs_blog_img_2_1.png");
const section_2_2 = require("../../../assets/img/blogs/gahs_blog_img_2_2.png");
const section_2_3 = require("../../../assets/img/blogs/gahs_blog_img_2_3.png");
const section_2_4 = require("../../../assets/img/blogs/gahs_blog_img_2_4.png");
const section_3 = require("../../../assets/img/blogs/gahs_blog_img_7.png");
const section_4 = require("../../../assets/img/blogs/gahs_blog_img_6.png");
const section_5 = require("../../../assets/img/blogs/gahs_blog_img_3.png");
const section_6 = require("../../../assets/img/blogs/gahs_blog_img_4.png");
const section_7 = require("../../../assets/img/blogs/gahs_blog_img_5.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Generative AI in HR: Automate & Empower Teams"
        description="Enhance employee productivity with summarized answers using Generative AI."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section className="w-100 float-left blog_top_header_upt gahs_blog_header_upt">
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile ? "text-align-center" : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-bigger color-black line-height-18">
                    The role of&nbsp;
                    <span
                      style={{ backgroundColor: "#FFFA7E" }}
                      className="blog-text-color-padding"
                    >
                      Generative AI
                    </span>{" "}
                    in&nbsp;
                    <span
                      style={{ backgroundColor: "#FFFA7E" }}
                      className="blog-text-color-padding"
                    >
                      HR support
                    </span>
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-bigger color-black">
                    The role of <br />
                    <span
                      style={{ backgroundColor: "#FFFA7E" }}
                      className="blog-text-color-padding"
                    >
                      Generative AI <br />
                    </span>{" "}
                    in&nbsp;
                    <span
                      style={{ backgroundColor: "#FFFA7E" }}
                      className="blog-text-color-padding"
                    >
                      HR support
                    </span>
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Generative AI for customer-facing business functions
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. Generative AI and its NLP capabilities for virtual employee
                  service desks
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. Challenges of virtual employee service desk Generative AI
                  can solve (use cases)
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                One of the many business functions that see a significant
                transformation via{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                    {" "}
                    Generative AI-based question-answering
                  </a>
                  &nbsp;
                </span>
                capability is HR service delivery. HR functions display the
                complex nature of administrative work.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Some ask about the status of the quarterly performance report,
                while salary or hire-to-retire-related stakeholder queries are
                bound to be hectic on the nervous systems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                More labor-intensive HR tasks comprise documentation for
                onboarding, final settlement during the offboarding procedures,
                appraisal, and constant communications between stakeholders and
                HR systems for payment sanctions or approval needs.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Unfortunately, heavy-loaded HR priorities that need high-level
                attention may pose a hurdle to task management and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-chatbot-for-employee-service">
                  employee service delivery
                </a>{" "}
                while impeding the most significant strategic HR functions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/hr-help-desk-guide">
                  HR help desk
                </a>{" "}
                is limited by constraints on the inability to provide help for
                common employee questions comprising 一
              </p>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials-medium color-black">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  My attendance has missing log-ins, how can I fix it?
                </li>
                <li className="font-section-normal-text-testimonials-medium color-black">
                  The payday is nearing. How can I update my account details?
                </li>
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Are there any tax liabilities?
                </li>
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Can I have more clarity on salary deductions?
                </li>
                <li className="font-section-normal-text-testimonials-medium color-black">
                  What’s the update on my transfer request?
                </li>
                <li className="font-section-normal-text-testimonials-medium color-black">
                  When can I expect my confirmation letter?
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                These questions often come up via emails, so they get lost in
                the inbox and go unnoticed.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Though automated HR support can answer these questions, they can
                be limited and outdated. As a result, traditional HR service
                delivery or{" "}
                <a href="https://workativ.com/conversational-ai-platform/faq-chatbot-builder">
                  FAQ-based knowledge base
                </a>{" "}
                of articles that help automate employee queries and service
                delivery can be repetitive for{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  follow-up questions,
                </span>{" "}
                resulting in a decline in employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Additionally, AI tools in HR have not been widely used in the HR
                space. Generative AI has changed this mindset in the human
                resource landscape with its unique ability to create content and
                perform NLP tasks for HR discipline, giving executives a better
                way to make HR processes more powerful and efficient.
              </p>
              <img src={section_1} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  At the same time,{" "}
                  <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                    Generative AI for HR support
                  </a>
                </span>{" "}
                has massive potential to augment automation capabilities for
                common employee queries and empower human resources to be
                productive and efficient across many HR support tasks.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Generative AI for HR use cases and benefits
              </h2>
              <h3 className="font-section-sub-header-small-home">
                HR use cases:
              </h3>
              <Carousel
                swipeable={true}
                showThumbs={false}
                showIndicators={true}
                showStatus={false}
                showArrows={false}
                useKeyboardArrows={true}
                emulateTouch={true}
              >
                <div>
                  <img src={section_2_1} />
                </div>
                <div>
                  <img src={section_2_2} />
                </div>
                <div>
                  <img src={section_2_3} />
                </div>
                <div>
                  <img src={section_2_4} />
                </div>
              </Carousel>

              <p className="font-section-normal-text-testimonials line-height-18 mt-5">
                HR functions are endless. Generative AI tends to automate these
                tasks by allowing organizations to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023">
                  streamline HR operations
                </a>
                using various large language model use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At the same time, organizations can look to free HR
                professionals to become more efficient towards strategic
                business priorities and help them drive business growth.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Helping HR address skills gap and nurture new learning for
                employees
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A business will grow if it aligns with the ever-changing
                business needs as new technologies and tools emerge.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                There are productivity tools, project management tools,
                communications tools - and many more for productivity gains
                across different projects for product-based or service-based
                companies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If a new technology trend arrives, businesses must ensure their
                people also know it and use it wisely to progress.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Say{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-vs-conversational-ai">
                  ChatGPT
                </a>{" "}
                is trending, and the business is aware of its business potential
                as well as risks. Employees not trained in these tools are less
                likely to drive effective business results.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  GenAI-based HR copilot
                </a>{" "}
                can help the HR team, and stakeholders get a view into employee
                performance or reveal which employee lacks appropriate expertise
                or needs a push to learn and excel.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI syncs with HR systems and creates a copilot to be
                widely used to prepare a new learning and development strategy
                for employees in a more personalized way.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this particular scenario, Generative AI can fetch data across
                various HR systems or internal applications where employees
                work, or employee data inhabit to help build a personalized
                learning program.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Additionally, Generative AI offers HR teams conversational
                workflows through integration with business or communication
                systems. It allows internal employees to leverage essential
                learning and development materials and upskill themselves easily
                without expecting HR intervention autonomously.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a result, HR executives can harness the power of Generative
                AI and empower employees to contribute to core business
                functions and drive business resilience.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Recruiting or hiring seamlessly
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI helps draw real-time benefits from crucial HR practices
                like hiring or recruiting.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generally, we see HR executives need to create a more meaningful
                job description that best conveys the right message to the
                applicants and drives cost-efficiency for recruitment drives
                across various job portals.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Being unable to create descriptions tailored to specific job
                roles in time, the recruitment process gets delayed.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The longer the time you need to write a description, the more
                your business suffers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Known to create exceptional content, HR practitioners can
                leverage Generative AI to create hyper-tailored job descriptions
                using large language model systems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI removes the need for a subject matter expert to create job
                descriptions. Instead, managers or executives can seek help from
                junior personnel in crafting a job description using
                business-specific information available on HR systems or through
                data systems giving accurate information about a specific role
                with significant responsibilities.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In addition to this, GenAI can help build a{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-support-human">
                  human-centric workplace experience.
                </a>{" "}
                With HR executives saving time from mundane and repetitive
                tasks, they can offer more empathetic responses to candidates
                through the hiring journey, making recruiting a more seamless HR
                process.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Onboarding and development
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                An onboarding process isn’t quite a good experience for HR and
                employees either.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is a huge list of tasks that need to be done accordingly so a
                new hire feels connected, and your organization need not create
                an opportunity that results in attrition.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI conversational workflows are as good as you can
                expect them to be.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There is no constant question and answer from a new hire, yet
                employee onboarding takes place peacefully and without much
                chaos or confusion.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Be it a remote setting or an onsite arrangement,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/gen-ai-workforce-it-support-future">
                  GenAI-based onboarding workflows
                </a>{" "}
                can efficiently connect with the candidate and allow him to be
                self-reliant in performing necessary actions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI for HR, when implemented with your human resource
                system, new hires are expected to obtain personalized HR support
                to perform all formalities and get started easily and
                successfully.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The new hire can{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-service-desk-cost-savings">
                  experience a seamless benefit
                </a>{" "}
                because he can effortlessly get massive HR support through a
                GenAI-based workflow system that guides him to proceed with
                documentation processes, get the necessary applications, receive
                welcome messages, or get access to organizational knowledge
                bases or specific role-based knowledge articles to learn and
                perform better.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Like onboarding, GenAI also makes it easy and seamless to handle
                offboarding processes for a leaving employee using
                conversational workflows.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With automated workflows, employees can follow instructions to
                get deactivated from organizational systems, settle final
                payments, etc., which is otherwise very cumbersome and hectic
                when handled manually.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Hyper-scale self-service
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI-based HR support is highly preferable for users
                today because it has a self-service interface backed by
                personalized and summarized answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In the past, HR service delivery could see little adoption of
                self-service portals due to the need for tailored employee
                support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say an employee wants access to a project management tool for a
                particular information on a client project.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A rudimentary chatbot surfaces links to documents. After reading
                through these lengthy documents, it is found that the company
                has migrated to a new project management tool.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Not only does this lead your team member to lose precious time
                after navigating these articles, but it also doesn’t solve his
                problem, leaving him to seek an agent’s help to find the latest
                information and work.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The cost of employee frustration and disengagement is another
                piece of employee grievances.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is a friction-free experience for your employees as and when
                they leverage Generative AI-based conversational workflows to
                fetch the latest information and get productive using summarized
                and straightforward answers in place of a lift of related
                document folders or links.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The same flexibility can make the HR experience more enriching.
                In a particular scenario, HR can quickly surface summarized
                notes of employee interactions through a ticket journey. This
                translates into significant benefits for agents as they can
                easily fetch the history of user requests, making it seamless
                for agents to handle queries and solve them at scale, whether a
                similar agent handles the case or not.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                One more efficacy of a Generative{" "}
                <a href="https://workativ.com/conversational-ai-platform/self-service-automation">
                  AI self-service interface
                </a>{" "}
                is that HR executives can quickly generate responses according
                to user’s request and reduce the time to solve a case.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Data governance and change management
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/increase-employee-productivity">
                  Employee well-being
                </a>{" "}
                is integral to any company’s growth. However, providing
                employees with a safe and healthy work culture constitutes a
                thriving workplace, helping businesses maintain data compliance
                and ensure social responsibility.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI-based HR workflows can automate tasks and harness advanced
                insights through integrations with HR systems or other business
                applications.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Playing around with this huge volume of data gives you deeper
                insights into which employees dedicate more hours to business
                operations, who have uninterrupted hours at work without any
                vacation, which employees lodge complaints, and so on.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                These insights can be handy in allocating necessary resources to
                align with employee expectations, building a better strategy to
                help employees thrive, and giving them more personalized
                services to enjoy their work and succeed.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Automation of repetitive HR tasks
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  BCG confirms that Generative AI can create content three times
                  faster
                </span>{" "}
                using company insights and data. This use case is extremely
                helpful for HR practitioners in automating NLP-based HR tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generation of recruiting or hiring notifications or job
                descriptions can be automated using NLP-based queries inside the
                Generative AI-based HR systems. HR professionals can save time
                by prompting GenAI-based tools to create any form of content,
                such as short and condensed forms of hiring alerts for social
                media channels, very comprehensive job descriptions to be used
                on the company website or other dedicated job portals, employee
                policy, a revised employee policy, company culture playbook,
                etc.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/top-8-human-resource-tasks-to-automate-for-better-employee-experience">
                  With HR tasks becoming flexible and easy,
                </a>{" "}
                HR teams can handle many other critical tasks that take time or
                drive data-driven decisions for more efficient task enablement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instead of selecting candidates manually, HR copilots can
                provide insights into skills that closely match the candidates'
                profiles and help proceed to the next step.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR tasks such as company-wide announcements have become a task
                for junior-level HR executives, giving senior executives more
                time to focus on growth and a competitive edge in the market.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Payroll and compensation communications
              </h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="salary breakup through self-service interface"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                An HR copilot is effective in harnessing insights into employee
                performance to decide appraisal or performance bonus structures.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Similarly, communicating{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  salary breakups,
                </a>{" "}
                bonuses, or probation performance pay is easy through Generative
                AI chatbots.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR copilots always have the latest data to update employees or
                HR professionals via NLP-based queries in a chatbot.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The effectiveness of automated HR communications is such that HR
                teams can reduce the load of responsibilities at a large scale
                yet help employees be satisfied with unique questions.
              </p>
              <h3 className="font-section-normal-text-medium">Benefits:</h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="generative AI HR benefits"
              />
              <h3 className="font-section-sub-header-small-home">
                L&D materials
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Boston Consulting Group,
                </span>{" "}
                in its report, confirmed that
                <span className="font-section-normal-text-testimonials-medium color-black">
                  &nbsp;Generative AI for HR reduces the time
                </span>{" "}
                to create content to only 9 days from 42 days.{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Learning and development
                </span>{" "}
                courses need fewer iterations and can be approved with 20% of
                customized suggestions.
              </p>
              <h3 className="font-section-sub-header-small-home">
                More answers for employees{" "}
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Conversational AI with GenAI capabilities enables employees to
                fetch more meaningful answers from the intuitive interface and
                helps employees solve problems autonomously. As a result,
                businesses derive faster problem-solving using{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  QnAs
                </span>{" "}
                through FAQs or comprehensive and custom answer generation
                capabilities. This extended capability allows businesses to
                deliver faster approval times by 40% and reduce the number of HR
                tickets to the HR help desk.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Productivity gains
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR executives can have more flexibility to experience progress
                on a task on all boards. GenAI can accelerate content generation
                3 times faster, driving down the number of HR teams to
                contribute to content generation and approvals. At the same
                time, companies can gain 60% cost savings on L&D content, email
                generation, company policy development, etc.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Which is better 一 an LLM-powered HR support or a Retrieval
                Automated Generation system?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                In our previous articles, we have provided enough context as to
                why LLM-powered chatbots or question-answering frameworks can
                limit the accuracy and relevancy of employee queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  <a href="https://workativ.com/conversational-ai-platform/blog/evaluate-llm-enterprise-user-support">
                    LLM or large language model
                  </a>{" "}
                  contains internet-scale data,&nbsp;
                </span>
                meaning LLM-powered chatbot trained on data comprising ebooks,
                various websites, messaging tools, Wikipedia, interviews, and
                many more data sources.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, one constraint is that the data has a cutoff time for a
                certain period valid for employee support only to that specific
                time and not beyond.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLMs unable to absorb the latest data continuously can expose
                users to irrelevant probabilities, resulting in inaccurate and
                less meaningful answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At the same time, black-box theory being integral to LLM data
                sources, it is tough to discern which source an answer is being
                retrieved from. As a result, LLM-powered answers lack veracity
                and produce hallucinations, which may otherwise lead employees
                to perform some inappropriate actions, impacting business
                results and reputation.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="vague answers from an LLM-based interface"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                If your GenAI-based question-answering interface for HR pulls
                data from a large language model, your HR people cannot provide
                or access accurate company-related information. Instead, they
                can get vague answers, which is useless to solve a problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say an HR needs to assess an employee's performance for a
                specific client project on cloud migration to determine his
                performance bonus calculations. An HR system backed by an
                LLM-powered chatbot or interface can surface information that is
                very generic and incomplete.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Though it automates response, businesses are unlikely to get a
                resolution and leverage real-time business results.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="More meaningful answers from a RAG-based chat interface"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI with an{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/rag-user-support">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    RAG model
                  </span>{" "}
                </a>
                connected to the HR domain data structure or company-wide data
                can help your organization overcome the limitation of an
                LLM-powered HR system and offers HR discipline incredible
                possibilities in retrieving domain-specific HR information or
                people data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Focusing on a similar challenge for finding information for an
                employee on a cloud migration project, if your GenAI-based HR
                system has a RAG connector with your HR data, you can retrieve
                crucial data that could help you decide performance contribution
                to various segments and calculate accurate bonus percentage.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                RAG brings several benefits in providing accurate and relevant
                information to employees and HR for a particular task.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Whenever a new update or event occurs, a RAG model pulls up that
                data and enhances information accuracy.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At the same time, if your bottom-line budget is limited, yet you
                want to drive similar business performance and results like that
                of RAG,{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                is also an efficient tool to redefine your existing HR processes
                and make employee support simpler and more intuitive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A slight difference exists in how Knowledge AI is trained from
                an RAG being trained.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI appears to be a cost-effective solution for SMBS to
                augment knowledge search and transform HR use cases or tasks
                since its training isn’t comprehensive like RAG, which needs
                custom training solutions with databases and computational
                resources.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does Workativ facilitate effective HR support?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ delivers what SMBs or enterprise leaders expect to
                drive from Generative AI capabilities.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ conversational AI
                </a>{" "}
                is a powerful machine learning tool to simplify employee
                collaborations and communications through effective knowledge
                discovery and autonomous problem-solving on the employee query
                side.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To make it easy for existing users and business leaders growing
                Generative AI interests to leverage the large language model
                capabilities for HR support,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Workativ allows easy integration of Knowledge AI with
                  conversational AI.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With the integration of Knowledge AI in its conversational AI
                platform, business leaders can allow employees to ask common,
                sometimes more personal, or custom questions in an ongoing
                conversation chat window and get answers to solve productivity
                issues.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s platform solves common queries conveniently for
                employees through a chatbot inside familiar communication
                channels where employees stay. As a result, HR service desks
                have less frequent ticket requests to handle and have more time
                to engage in critical problems and reduce downtime.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Using{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                  Workativ’s platform,
                </a>{" "}
                users can solve everyday employee problems related to,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials"> PTO</li>
                <li className="font-section-normal-text-testimonials">Tax</li>
                <li className="font-section-normal-text-testimonials">
                  Salary structure
                </li>
                <li className="font-section-normal-text-testimonials">
                  Email access
                </li>
                <li className="font-section-normal-text-testimonials">
                  Asset requests
                </li>
                <li className="font-section-normal-text-testimonials">
                  User provision
                </li>
                <li className="font-section-normal-text-testimonials">
                  Bonus details, etc
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In addition to offering employee benefits related to common
                problem-solving, Workativ’s Knowledge AI, open to being trained
                with Workativ data, external data, or internal data, delivers
                accurate and contextual answers by launching a semantic search.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Users are twice as likely to increase adoption flexibility with
                Knowledge AI and gain autonomous problem-solving capabilities
                for common and unique problems.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="enhanced chatbot support built on conversational AI 
                "
              />

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Want to know how can you leverage Generative AI in HR through
                Knowledge AI and take control of HR support?{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Schedule a call with Workativ
                </a>{" "}
                today.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Generative AI for customer-facing business functions
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. Generative AI and its NLP capabilities for virtual
                    employee service desks
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. Challenges of virtual employee service desk Generative AI
                    can solve (use cases)
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                One of the many business functions that see a significant
                transformation via{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-chatbot-guide">
                    {" "}
                    Generative AI-based question-answering
                  </a>
                  &nbsp;
                </span>
                capability is HR service delivery. HR functions display the
                complex nature of administrative work.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Some ask about the status of the quarterly performance report,
                while salary or hire-to-retire-related stakeholder queries are
                bound to be hectic on the nervous systems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                More labor-intensive HR tasks comprise documentation for
                onboarding, final settlement during the offboarding procedures,
                appraisal, and constant communications between stakeholders and
                HR systems for payment sanctions or approval needs.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Unfortunately, heavy-loaded HR priorities that need high-level
                attention may pose a hurdle to task management and{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/conversational-ai-chatbot-for-employee-service">
                  employee service delivery
                </a>{" "}
                while impeding the most significant strategic HR functions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                So, the{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/hr-help-desk-guide">
                  HR help desk
                </a>{" "}
                is limited by constraints on the inability to provide help for
                common employee questions comprising 一
              </p>

              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials-medium color-black">
                <li className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  My attendance has missing log-ins, how can I fix it?
                </li>
                <li className="font-section-normal-text-testimonials-medium color-black">
                  The payday is nearing. How can I update my account details?
                </li>
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Are there any tax liabilities?
                </li>
                <li className="font-section-normal-text-testimonials-medium color-black">
                  Can I have more clarity on salary deductions?
                </li>
                <li className="font-section-normal-text-testimonials-medium color-black">
                  What’s the update on my transfer request?
                </li>
                <li className="font-section-normal-text-testimonials-medium color-black">
                  When can I expect my confirmation letter?
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18">
                These questions often come up via emails, so they get lost in
                the inbox and go unnoticed.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Though automated HR support can answer these questions, they can
                be limited and outdated. As a result, traditional HR service
                delivery or{" "}
                <a href="https://workativ.com/conversational-ai-platform/faq-chatbot-builder">
                  FAQ-based knowledge base
                </a>{" "}
                of articles that help automate employee queries and service
                delivery can be repetitive for{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  follow-up questions,
                </span>{" "}
                resulting in a decline in employee experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Additionally, AI tools in HR have not been widely used in the HR
                space. Generative AI has changed this mindset in the human
                resource landscape with its unique ability to create content and
                perform NLP tasks for HR discipline, giving executives a better
                way to make HR processes more powerful and efficient.
              </p>
              <img src={section_1} className="blog_image_top_bt" />
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  At the same time,{" "}
                  <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                    Generative AI for HR support
                  </a>
                </span>{" "}
                has massive potential to augment automation capabilities for
                common employee queries and empower human resources to be
                productive and efficient across many HR support tasks.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs mt-3">
                Generative AI for HR use cases and benefits
              </h2>
              <h3 className="font-section-sub-header-small-home">
                HR use cases:
              </h3>
              <Carousel
                swipeable={true}
                showThumbs={false}
                showIndicators={true}
                showStatus={false}
                showArrows={false}
              >
                <div>
                  <img src={section_2_1} />
                </div>
                <div>
                  <img src={section_2_2} />
                </div>
                <div>
                  <img src={section_2_3} />
                </div>
                <div>
                  <img src={section_2_4} />
                </div>
              </Carousel>

              <p className="font-section-normal-text-testimonials line-height-18 mt-4">
                HR functions are endless. Generative AI tends to automate these
                tasks by allowing organizations to{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023">
                  streamline HR operations
                </a>
                using various large language model use cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At the same time, organizations can look to free HR
                professionals to become more efficient towards strategic
                business priorities and help them drive business growth.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Helping HR address skills gap and nurture new learning for
                employees
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                A business will grow if it aligns with the ever-changing
                business needs as new technologies and tools emerge.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                There are productivity tools, project management tools,
                communications tools - and many more for productivity gains
                across different projects for product-based or service-based
                companies.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If a new technology trend arrives, businesses must ensure their
                people also know it and use it wisely to progress.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Say{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/chatgpt-vs-conversational-ai">
                  ChatGPT
                </a>{" "}
                is trending, and the business is aware of its business potential
                as well as risks. Employees not trained in these tools are less
                likely to drive effective business results.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  GenAI-based HR copilot
                </a>{" "}
                can help the HR team, and stakeholders get a view into employee
                performance or reveal which employee lacks appropriate expertise
                or needs a push to learn and excel.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI syncs with HR systems and creates a copilot to be
                widely used to prepare a new learning and development strategy
                for employees in a more personalized way.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In this particular scenario, Generative AI can fetch data across
                various HR systems or internal applications where employees
                work, or employee data inhabit to help build a personalized
                learning program.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Additionally, Generative AI offers HR teams conversational
                workflows through integration with business or communication
                systems. It allows internal employees to leverage essential
                learning and development materials and upskill themselves easily
                without expecting HR intervention autonomously.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                As a result, HR executives can harness the power of Generative
                AI and empower employees to contribute to core business
                functions and drive business resilience.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Recruiting or hiring seamlessly
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI helps draw real-time benefits from crucial HR practices
                like hiring or recruiting.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generally, we see HR executives need to create a more meaningful
                job description that best conveys the right message to the
                applicants and drives cost-efficiency for recruitment drives
                across various job portals.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Being unable to create descriptions tailored to specific job
                roles in time, the recruitment process gets delayed.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                The longer the time you need to write a description, the more
                your business suffers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Known to create exceptional content, HR practitioners can
                leverage Generative AI to create hyper-tailored job descriptions
                using large language model systems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI removes the need for a subject matter expert to create job
                descriptions. Instead, managers or executives can seek help from
                junior personnel in crafting a job description using
                business-specific information available on HR systems or through
                data systems giving accurate information about a specific role
                with significant responsibilities.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In addition to this, GenAI can help build a{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-support-human">
                  human-centric workplace experience.
                </a>{" "}
                With HR executives saving time from mundane and repetitive
                tasks, they can offer more empathetic responses to candidates
                through the hiring journey, making recruiting a more seamless HR
                process.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Onboarding and development
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                An onboarding process isn’t quite a good experience for HR and
                employees either.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is a huge list of tasks that need to be done accordingly so a
                new hire feels connected, and your organization need not create
                an opportunity that results in attrition.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI conversational workflows are as good as you can
                expect them to be.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                There is no constant question and answer from a new hire, yet
                employee onboarding takes place peacefully and without much
                chaos or confusion.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Be it a remote setting or an onsite arrangement,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/gen-ai-workforce-it-support-future">
                  GenAI-based onboarding workflows
                </a>{" "}
                can efficiently connect with the candidate and allow him to be
                self-reliant in performing necessary actions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI for HR, when implemented with your human resource
                system, new hires are expected to obtain personalized HR support
                to perform all formalities and get started easily and
                successfully.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The new hire can{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/generative-ai-service-desk-cost-savings">
                  experience a seamless benefit
                </a>{" "}
                because he can effortlessly get massive HR support through a
                GenAI-based workflow system that guides him to proceed with
                documentation processes, get the necessary applications, receive
                welcome messages, or get access to organizational knowledge
                bases or specific role-based knowledge articles to learn and
                perform better.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Like onboarding, GenAI also makes it easy and seamless to handle
                offboarding processes for a leaving employee using
                conversational workflows.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                With automated workflows, employees can follow instructions to
                get deactivated from organizational systems, settle final
                payments, etc., which is otherwise very cumbersome and hectic
                when handled manually.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Hyper-scale self-service
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI-based HR support is highly preferable for users
                today because it has a self-service interface backed by
                personalized and summarized answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                In the past, HR service delivery could see little adoption of
                self-service portals due to the need for tailored employee
                support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say an employee wants access to a project management tool for a
                particular information on a client project.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A rudimentary chatbot surfaces links to documents. After reading
                through these lengthy documents, it is found that the company
                has migrated to a new project management tool.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Not only does this lead your team member to lose precious time
                after navigating these articles, but it also doesn’t solve his
                problem, leaving him to seek an agent’s help to find the latest
                information and work.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The cost of employee frustration and disengagement is another
                piece of employee grievances.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                It is a friction-free experience for your employees as and when
                they leverage Generative AI-based conversational workflows to
                fetch the latest information and get productive using summarized
                and straightforward answers in place of a lift of related
                document folders or links.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The same flexibility can make the HR experience more enriching.
                In a particular scenario, HR can quickly surface summarized
                notes of employee interactions through a ticket journey. This
                translates into significant benefits for agents as they can
                easily fetch the history of user requests, making it seamless
                for agents to handle queries and solve them at scale, whether a
                similar agent handles the case or not.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                One more efficacy of a Generative{" "}
                <a href="https://workativ.com/conversational-ai-platform/self-service-automation">
                  AI self-service interface
                </a>{" "}
                is that HR executives can quickly generate responses according
                to user’s request and reduce the time to solve a case.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Data governance and change management
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/increase-employee-productivity">
                  Employee well-being
                </a>{" "}
                is integral to any company’s growth. However, providing
                employees with a safe and healthy work culture constitutes a
                thriving workplace, helping businesses maintain data compliance
                and ensure social responsibility.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                GenAI-based HR workflows can automate tasks and harness advanced
                insights through integrations with HR systems or other business
                applications.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Playing around with this huge volume of data gives you deeper
                insights into which employees dedicate more hours to business
                operations, who have uninterrupted hours at work without any
                vacation, which employees lodge complaints, and so on.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                These insights can be handy in allocating necessary resources to
                align with employee expectations, building a better strategy to
                help employees thrive, and giving them more personalized
                services to enjoy their work and succeed.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Automation of repetitive HR tasks
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  BCG confirms that Generative AI can create content three times
                  faster
                </span>{" "}
                using company insights and data. This use case is extremely
                helpful for HR practitioners in automating NLP-based HR tasks.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Generation of recruiting or hiring notifications or job
                descriptions can be automated using NLP-based queries inside the
                Generative AI-based HR systems. HR professionals can save time
                by prompting GenAI-based tools to create any form of content,
                such as short and condensed forms of hiring alerts for social
                media channels, very comprehensive job descriptions to be used
                on the company website or other dedicated job portals, employee
                policy, a revised employee policy, company culture playbook,
                etc.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/blog/top-8-human-resource-tasks-to-automate-for-better-employee-experience">
                  With HR tasks becoming flexible and easy,
                </a>{" "}
                HR teams can handle many other critical tasks that take time or
                drive data-driven decisions for more efficient task enablement.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instead of selecting candidates manually, HR copilots can
                provide insights into skills that closely match the candidates'
                profiles and help proceed to the next step.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR tasks such as company-wide announcements have become a task
                for junior-level HR executives, giving senior executives more
                time to focus on growth and a competitive edge in the market.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Payroll and compensation communications
              </h3>
              <img
                src={section_3}
                className="blog_image_top_bt"
                alt="salary breakup through self-service interface"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                An HR copilot is effective in harnessing insights into employee
                performance to decide appraisal or performance bonus structures.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Similarly, communicating{" "}
                <a href="https://workativ.com/conversational-ai-platform/hr-chatbot">
                  salary breakups,
                </a>{" "}
                bonuses, or probation performance pay is easy through Generative
                AI chatbots.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR copilots always have the latest data to update employees or
                HR professionals via NLP-based queries in a chatbot.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                The effectiveness of automated HR communications is such that HR
                teams can reduce the load of responsibilities at a large scale
                yet help employees be satisfied with unique questions.
              </p>
              <h3 className="font-section-normal-text-medium">Benefits:</h3>
              <img
                src={section_4}
                className="blog_image_top_bt"
                alt="generative AI HR benefits"
              />
              <h3 className="font-section-sub-header-small-home">
                L&D materials
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Boston Consulting Group,
                </span>{" "}
                in its report, confirmed that
                <span className="font-section-normal-text-testimonials-medium color-black">
                  &nbsp;Generative AI for HR reduces the time
                </span>{" "}
                to create content to only 9 days from 42 days.{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  Learning and development
                </span>{" "}
                courses need fewer iterations and can be approved with 20% of
                customized suggestions.
              </p>
              <h3 className="font-section-sub-header-small-home">
                More answers for employees{" "}
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                Conversational AI with GenAI capabilities enables employees to
                fetch more meaningful answers from the intuitive interface and
                helps employees solve problems autonomously. As a result,
                businesses derive faster problem-solving using{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  QnAs
                </span>{" "}
                through FAQs or comprehensive and custom answer generation
                capabilities. This extended capability allows businesses to
                deliver faster approval times by 40% and reduce the number of HR
                tickets to the HR help desk.
              </p>
              <h3 className="font-section-sub-header-small-home">
                Productivity gains
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18">
                HR executives can have more flexibility to experience progress
                on a task on all boards. GenAI can accelerate content generation
                3 times faster, driving down the number of HR teams to
                contribute to content generation and approvals. At the same
                time, companies can gain 60% cost savings on L&D content, email
                generation, company policy development, etc.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Which is better 一 an LLM-powered HR support or a Retrieval
                Automated Generation system?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                In our previous articles, we have provided enough context as to
                why LLM-powered chatbots or question-answering frameworks can
                limit the accuracy and relevancy of employee queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <span className="font-section-normal-text-testimonials-medium color-black">
                  <a href="https://workativ.com/conversational-ai-platform/blog/evaluate-llm-enterprise-user-support">
                    LLM or large language model
                  </a>{" "}
                  contains internet-scale data,&nbsp;
                </span>
                meaning LLM-powered chatbot trained on data comprising ebooks,
                various websites, messaging tools, Wikipedia, interviews, and
                many more data sources.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                However, one constraint is that the data has a cutoff time for a
                certain period valid for employee support only to that specific
                time and not beyond.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                LLMs unable to absorb the latest data continuously can expose
                users to irrelevant probabilities, resulting in inaccurate and
                less meaningful answers.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At the same time, black-box theory being integral to LLM data
                sources, it is tough to discern which source an answer is being
                retrieved from. As a result, LLM-powered answers lack veracity
                and produce hallucinations, which may otherwise lead employees
                to perform some inappropriate actions, impacting business
                results and reputation.
              </p>
              <img
                src={section_5}
                className="blog_image_top_bt"
                alt="vague answers from an LLM-based interface"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                If your GenAI-based question-answering interface for HR pulls
                data from a large language model, your HR people cannot provide
                or access accurate company-related information. Instead, they
                can get vague answers, which is useless to solve a problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Say an HR needs to assess an employee's performance for a
                specific client project on cloud migration to determine his
                performance bonus calculations. An HR system backed by an
                LLM-powered chatbot or interface can surface information that is
                very generic and incomplete.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Though it automates response, businesses are unlikely to get a
                resolution and leverage real-time business results.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="More meaningful answers from a RAG-based chat interface"
              />
              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI with an{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/rag-user-support">
                  <span className="font-section-normal-text-testimonials-medium color-black">
                    RAG model
                  </span>{" "}
                </a>
                connected to the HR domain data structure or company-wide data
                can help your organization overcome the limitation of an
                LLM-powered HR system and offers HR discipline incredible
                possibilities in retrieving domain-specific HR information or
                people data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Focusing on a similar challenge for finding information for an
                employee on a cloud migration project, if your GenAI-based HR
                system has a RAG connector with your HR data, you can retrieve
                crucial data that could help you decide performance contribution
                to various segments and calculate accurate bonus percentage.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                RAG brings several benefits in providing accurate and relevant
                information to employees and HR for a particular task.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Whenever a new update or event occurs, a RAG model pulls up that
                data and enhances information accuracy.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                At the same time, if your bottom-line budget is limited, yet you
                want to drive similar business performance and results like that
                of RAG,{" "}
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  Knowledge AI
                </a>{" "}
                is also an efficient tool to redefine your existing HR processes
                and make employee support simpler and more intuitive.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                A slight difference exists in how Knowledge AI is trained from
                an RAG being trained.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Knowledge AI appears to be a cost-effective solution for SMBS to
                augment knowledge search and transform HR use cases or tasks
                since its training isn’t comprehensive like RAG, which needs
                custom training solutions with databases and computational
                resources.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                How does Workativ facilitate effective HR support?
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ delivers what SMBs or enterprise leaders expect to
                drive from Generative AI capabilities.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ conversational AI
                </a>{" "}
                is a powerful machine learning tool to simplify employee
                collaborations and communications through effective knowledge
                discovery and autonomous problem-solving on the employee query
                side.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To make it easy for existing users and business leaders growing
                Generative AI interests to leverage the large language model
                capabilities for HR support,{" "}
                <span className="font-section-normal-text-testimonials-medium color-black">
                  {" "}
                  Workativ allows easy integration of Knowledge AI with
                  conversational AI.
                </span>
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With the integration of Knowledge AI in its conversational AI
                platform, business leaders can allow employees to ask common,
                sometimes more personal, or custom questions in an ongoing
                conversation chat window and get answers to solve productivity
                issues.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ’s platform solves common queries conveniently for
                employees through a chatbot inside familiar communication
                channels where employees stay. As a result, HR service desks
                have less frequent ticket requests to handle and have more time
                to engage in critical problems and reduce downtime.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Using{" "}
                <a href="https://workativ.com/conversational-ai-platform/chatbot-builder">
                  Workativ’s platform,
                </a>{" "}
                users can solve everyday employee problems related to,
              </p>
              <ul className="float-left w-100 margin-ul-btm blogs_ul_upd font-section-normal-text-testimonials">
                <li className="font-section-normal-text-testimonials"> PTO</li>
                <li className="font-section-normal-text-testimonials">Tax</li>
                <li className="font-section-normal-text-testimonials">
                  Salary structure
                </li>
                <li className="font-section-normal-text-testimonials">
                  Email access
                </li>
                <li className="font-section-normal-text-testimonials">
                  Asset requests
                </li>
                <li className="font-section-normal-text-testimonials">
                  User provision
                </li>
                <li className="font-section-normal-text-testimonials">
                  Bonus details, etc
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                In addition to offering employee benefits related to common
                problem-solving, Workativ’s Knowledge AI, open to being trained
                with Workativ data, external data, or internal data, delivers
                accurate and contextual answers by launching a semantic search.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Users are twice as likely to increase adoption flexibility with
                Knowledge AI and gain autonomous problem-solving capabilities
                for common and unique problems.
              </p>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="enhanced chatbot support built on conversational AI 
                "
              />

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Want to know how can you leverage Generative AI in HR through
                Knowledge AI and take control of HR support?{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Schedule a call with Workativ
                </a>{" "}
                today.
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-digital-transformation-guide-2023"
              className="font-section-normal-text-testimonials"
            >
              The ultimate guide - 2023 HR DIGITAL TRANSFORMATION
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-help-desk-guide"
              className="font-section-normal-text-testimonials"
            >
              The ultimate guide - 2023 HR HELP DESK
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/hr-service-management-guide"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              A Complete Guide - 2023 HR Service Management
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
